import React, { useRef, useState } from "react";

import ProjectPreview, {
  type StyleDataProps
} from "components/common/project/ProjectPreview/ProjectPreview";
import { ProjectLink } from "components/common/project/ProjectPreview/ProjectPreview.styled";
import { ProjectsListWrapper } from "components/pages/projects/ProjectsList/ProjectsList.styled";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import ProjectData from "data/projects-data.json";
import { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";

const processProjectData = () => {
  const dataCopy = [...ProjectData.projects];

  return dataCopy.map(item => {
    return { ...item, imageOpacity: 1, cloudOpacity: 1 };
  });
};

export const ProjectsList = () => {
  const [projectsData] = useState(processProjectData());
  const [, setRefreshValue] = useState(1);
  const projectWrapper = useRef<HTMLElement>(null);
  const [, dispatch] = useScrollContext();

  useFirstRenderEffect(() => {
    ProjectData.projects.forEach(project => {
      dispatch(
        registerScrollAnimation({
          target: project.id + "-wrapper",
          animation: { opacity: 0.001, y: 100, duration: 0.75, ease: Circ.easeOut },
          triggerOffsetMultiplier: -0.4
        })
      );
      dispatch(
        registerScrollAnimation({
          target: project.id + "-description",
          animation: { opacity: 0.001, duration: 0.75, ease: Circ.easeOut },
          triggerOffsetMultiplier: -0.4,
          proxyElement: project.id + "-wrapper"
        })
      );
    });
    setRefreshValue(0);
  }, [projectsData]);

  return (
    <ProjectsListWrapper ref={projectWrapper}>
      {projectsData.map((project, index) => {
        return (
          <ProjectLink to={project.url} key={project.name}>
            <ProjectPreview
              projectData={project}
              styleData={index % 2 ? previewStyleInversed : previewStyle}
              imageOpacity={projectsData[index].imageOpacity}
              cloudOpacity={projectsData[index].cloudOpacity}
              projectId={project.id}
              withoutLink
            />
          </ProjectLink>
        );
      })}
    </ProjectsListWrapper>
  );
};

export default ProjectsList;

const previewStyle: StyleDataProps = {
  wrapperStyle: "big-project-wrapper",
  textWrapperStyle: "big-project-text-wrapper",
  cloudWrapperStyle: "big-project-cloud-wrapper",
  buttonStyle: "big-project-standard-button"
};

const previewStyleInversed: StyleDataProps = {
  wrapperStyle: "big-project-wrapper",
  textWrapperStyle: "big-project-text-wrapper-inversed",
  cloudWrapperStyle: "big-project-cloud-wrapper-inversed",
  buttonStyle: "big-project-standard-button"
};
