import React from "react";

import type { PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import PageTitle from "components/common/other/PageTitle/PageTitle";
import Layout from "components/layout/Layout/Layout";
import ProjectList from "components/pages/projects/ProjectsList/ProjectsList";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";

const Projects = ({ location }: PageProps) => {
  return (
    <>
      <GatsbySeo {...GetSeoData("Projects")} />
      <Layout location={location}>
        <MainCloud color="PinkViolet" variant="MainCloudWrapper" scrollSpeed={-2} />
        <PageTitle {...pageTitleData} />
        {/* To be added back, once we have more projects
         <ProjectCategories />
         */}
        <ProjectList />
      </Layout>
    </>
  );
};

export default Projects;

const pageTitleData: PageTitleProps = {
  instantPlayback: true,
  variant: "projects-title",
  isTitle: true,
  headers: [
    {
      text: "Projects_Header_Our",
      variant: "ProjectsOur",
      id: "projects-our",
      title: true,
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      horizontalScrollSpeed: 1,
      scrollSpeed: 2
    },
    {
      text: "Projects_Header_Work",
      variant: "ProjectsWork",
      id: "projects-work",
      title: true,
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0.75 },
      scrollSpeed: 1.5,
      horizontalScrollSpeed: -0.5
    }
  ],
  subtitle: {
    text: "Projects_Short_Intro",
    variant: "projects-subtitle"
  }
};
