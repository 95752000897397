import { Link } from "gatsby";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CommonLink } from "styled/common/font-styles/CommonFonts";
import { AnimatedTransformOpacityMixin, Background, CenterFlex } from "styled/common/Utils";
import styled, { css } from "styled-components";

export type ProjectWrapperVariant = "home-project-wrapper" | "big-project-wrapper";

const BigProjectWrapper = css`
  width: 100%;
  ${CalculateFromHeight("height", { mobile: 100, tablet: 90, desktop: 90 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 100, tablet: 50 })}
  ${CalculateFromHeight("margin-top", { mobile: 50, tablet: -8, desktop: 20 })}
`;

const HomeProjectWrapper = css`
  ${CalculateFromWidth("width", { mobile: 95, tablet: 95, desktop: 77.5 })}
  ${CalculateFromWidth("height", { mobile: 95, tablet: 95, desktop: 65 })}
  ${Background}
`;

export const ProjectWrapper = styled.div<{ variant: ProjectWrapperVariant }>`
  ${AnimatedTransformOpacityMixin}
  object-fit: contain;
  position: relative;
  ${CenterFlex}

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  ${({ variant }) => variant === "home-project-wrapper" && HomeProjectWrapper}
  ${({ variant }) => variant === "big-project-wrapper" && BigProjectWrapper}
`;

export const ProjectLink = styled(Link)`
  ${CommonLink}
`;
