import { CalculateFromHeight } from "styled/base/SizeFunctions";
import styled from "styled-components";

const ProjectHeight = 90;

export const ProjectsListWrapper = styled.section`
  width: 100%;
  ${CalculateFromHeight("min-height", {
    mobile: ProjectHeight * 4,
    tablet: ProjectHeight * 4,
    desktop: ProjectHeight * 4
  })}
  ${CalculateFromHeight("margin-bottom", { mobile: 10, tablet: 10, desktop: 10 })}
`;
