import React, { RefObject, useRef } from "react";

import type { StandardButtonVariant } from "components/common/buttons/ButtonStandard/ButtonStandard.styled";
import ProjectDescription from "components/common/project/ProjectDescription/ProjectDescription";
import type { ProjectTextVariant } from "components/common/project/ProjectDescription/ProjectDescription.styled";
import {
  ProjectWrapper,
  type ProjectWrapperVariant
} from "components/common/project/ProjectPreview/ProjectPreview.styled";
import RendererWrapper from "components/rendering/RendererWrapper/RendererWrapper";
import type { RendererWrapperVariant } from "components/rendering/RendererWrapper/RendererWrapper.styled";
import { addCloud, addRenderObject, useRenderContext } from "contexts/RenderContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";

export type ProjectProps = {
  id: number;
  previewOrder: number;
  name: string;
  description: string;
  url: string;
  cloudColor: string;
  imageName: string;
  imageOpacity: number;
  cloudOpacity: number;
};

export type StyleDataProps = {
  wrapperStyle: ProjectWrapperVariant;
  textWrapperStyle: ProjectTextVariant;
  cloudWrapperStyle: RendererWrapperVariant;
  buttonStyle?: StandardButtonVariant;
};

interface ProjectPreviewProps {
  projectData: ProjectProps;
  styleData: StyleDataProps;
  showText?: boolean;
  imageOpacity: number;
  cloudOpacity: number;
  projectId?: number;
  elementRef?: RefObject<HTMLDivElement>;
  withoutLink?: boolean;
}

export const ProjectPreview = ({
  projectData,
  styleData,
  showText = true,
  cloudOpacity = 1,
  projectId,
  elementRef,
  withoutLink
}: ProjectPreviewProps) => {
  const projectCloudWrapper = useRef<HTMLDivElement>(null);
  const projectImageWrapper = useRef<HTMLDivElement>(null);
  const [, dispatchRender] = useRenderContext();
  const cloud = {
    name: projectData.cloudColor,
    wrapper: projectCloudWrapper.current,
    group: "Project-" + projectData.name
  };
  const renderObject = {
    name: projectData.imageName,
    type: "ImageLayer",
    wrapper: projectImageWrapper.current
  };

  useFirstRenderEffect(() => {
    dispatchRender(addCloud(cloud));
    dispatchRender(addRenderObject(renderObject));
  }, [projectData, projectId]);

  return (
    <ProjectWrapper
      variant={styleData.wrapperStyle}
      id={typeof projectId !== "undefined" ? projectId + "-wrapper" : ""}
      ref={elementRef}
    >
      <RendererWrapper
        elementRef={projectCloudWrapper}
        variant={styleData.cloudWrapperStyle}
        style={{ opacity: cloudOpacity }}
        type="cloud"
        additionalData={projectData.cloudColor}
      >
        <RendererWrapper
          elementRef={projectImageWrapper}
          variant="project-image-wrapper"
          style={{ opacity: cloudOpacity }}
          type="image"
          additionalData={projectData.imageName}
        />
      </RendererWrapper>
      {showText && (
        <ProjectDescription
          withoutLink={withoutLink}
          projectData={projectData}
          styleData={styleData}
          id={typeof projectId !== "undefined" ? projectId + "-description" : ""}
        />
      )}
    </ProjectWrapper>
  );
};

export default ProjectPreview;
